import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/verify",
      component: () => import("@/view/layout/Layout"),
      meta: {
        requires_auth: true,
        title: "Dashboard - SimplyCSV",
        metaTags: [
          {
            name: "description",
            content: "The dashboard of SimplyCSV."
          },
          {
            property: "og:description",
            content: "The dashboard of SimplyCSV."
          }
        ]
      },
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            requires_auth: true,
            title: "Dashboard - SimplyCSV"
          }
        },

        {
          path: "/templates",
          name: "templates-list",
          component: () => import("@/view/pages/templates/TemplateList.vue"),
          meta: {
            requires_auth: true,
            title: "Templates - SimplyCSV"
          }
        },

        {
          path: "/import",
          name: "templates-import",
          component: () => import("@/view/pages/templates/TemplateImport.vue"),
          meta: {
            requires_auth: true,
            title: "Import - SimplyCSV"
          }
        },

        {
          path: "/templates/create",
          name: "template-create",
          component: () => import("@/view/pages/templates/TemplateCreate.vue"),
          meta: {
            requires_auth: true,
            title: "Create Template - SimplyCSV"
          }
        },

        {
          path: "/templates/:template_uuid",
          name: "template-edit",
          component: () => import("@/view/pages/templates/TemplateEdit.vue"),
          meta: {
            requires_auth: true,
            title: "Edit Template - SimplyCSV"
          }
        },

        {
          path: "/files",
          name: "files-list",
          component: () => import("@/view/pages/files/FilesList.vue"),
          meta: {
            requires_auth: true,
            title: "Files - SimplyCSV"
          }
        },
        {
          path: "/api-credentials",
          name: "api-credentials",
          component: () => import("@/view/pages/api/ApiCredentials.vue"),
          meta: {
            requires_auth: true,
            title: "API Credentials - SimplyCSV"
          }
        },

        /*{
          path: "/template-builder",
          name: "template-builder",
          component: () =>
            import("@/view/pages/templates/TemplateBuilderPage.vue"),
          meta: {
            title: "Template Builder - SimplyCSV"
          }
        },*/

        {
          path: "/account",
          name: "account",
          component: () => import("@/view/pages/account/Account.vue"),
          meta: {
            requires_auth: true,
            title: "Account - SimplyCSV",
            metaTags: [
              {
                name: "description",
                content: "The account of SimplyCSV."
              },
              {
                property: "og:description",
                content: "The account of SimplyCSV."
              }
            ]
          },
          children: [
            {
              path: "/account/profile",
              name: "profile",
              component: () => import("@/view/pages/account/Profile.vue"),
              meta: {
                requires_auth: true,
                title: "My Profile - SimplyCSV"
              }
            } /*,
            {
              path: "/auth0-logout",
              name: "auth0-logout",
              component: () => import("@/view/pages/account/Auth0Logout.vue"),
              meta: {
                requires_auth: true,
                title: "Logout - SimplyCSV"
              }
            }*/
          ]
        }
      ]
    },
    /*{
      name: "auth0-callback",
      path: "/auth0-callback",
      component: () => import("@/view/pages/auth/Auth0Callback"),
      meta: {
        requires_auth: false,
        title: "Auth0 - SimplyCSV"
      }
    },*/
    {
      path: "/auth",
      component: () => import("@/view/pages/auth/Login-1"),
      meta: {
        requires_auth: false,
        title: "Login - SimplyCSV",
        metaTags: [
          {
            name: "description",
            content: "The login of SimplyCSV."
          },
          {
            property: "og:description",
            content: "The login of SimplyCSV."
          }
        ]
      },
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login-1"),
          meta: {
            requires_auth: false,
            title: "Login - SimplyCSV"
          }
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Login-1"),
          meta: {
            requires_auth: false,
            title: "Register - SimplyCSV"
          }
        }
      ]
    },
    {
      path: "/verify",
      component: () => import("@/view/pages/auth/Verify"),
      meta: {
        requires_auth: false,
        title: "Verify - SimplyCSV"
      }
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
      meta: {
        requires_auth: false,
        title: "404 Not Found - SimplyCSV"
      }
    }
  ]
});
