import ApiService from "@/core/services/api.service";

export const GENERATE_KEY = "generateKey";

// mutation types
export const SET_KEY = "setKey";

const state = {
  key: ""
};

const getters = {
  key(state) {
    return state.key;
  }
};

const actions = {
  [GENERATE_KEY](context) {
    return new Promise(resolve => {
      ApiService.post("/reset-api-token").then(({ data }) => {
        context.commit(SET_KEY, data.token);
        resolve(data);
      });
    });
  }
};

const mutations = {
  [SET_KEY](state, data) {
    state.key = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
