import Vue from "vue";
import ApiService from "@/core/services/api.service";

// action types
export const LIST_TEMPLATES = "getTemplates";
export const SAVE_TEMPLATE = "saveTemplate";
export const SHOW_TEMPLATE = "getTemplate";
export const RESET_TEMPLATE = "resetTemplate";
export const DELETE_TEMPLATE = "deleteTemplate";
export const CREATE_TEMPLATE_FIELD = "createTemplateField";
export const UPDATE_TEMPLATE_FIELD = "updateTemplateField";
export const DELETE_TEMPLATE_FIELD = "deleteTemplateField";
export const DELETE_TEMPLATE_WEBHOOK = "deleteTemplateWebhook";
export const CREATE_TEMPLATE_WEBHOOK = "createTemplateWebhook";

// mutation types
export const SET_TEMPLATE = "setTemplate";
export const SET_TEMPLATES = "setTemplates";
export const PURGE_CURRENT_TEMPLATE = "purgeCurrentTemplate";
export const PURGE_TEMPLATE = "purgeTemplate";

export const SET_TEMPLATE_FIELD = "setTemplateField";
export const PURGE_TEMPLATE_FIELD = "purgeTemplateField";
export const REPLACE_TEMPLATE_FIELD = "replaceTemplateField";

export const SET_TEMPLATE_WEBHOOK = "setTemplateWebhook";
export const PURGE_TEMPLATE_WEBHOOK = "purgeTemplateField";

const state = {
  current_template: {
    uuid: "",
    name: "",
    type: "",
    fields: [],
    webhooks: []
  },
  templates: []
};

const getters = {
  currentTemplate(state) {
    return state.current_template;
  },
  templates(state) {
    return state.templates;
  }
};

const actions = {
  [LIST_TEMPLATES](context, payload) {
    return new Promise(resolve => {
      ApiService.get("/templates", payload).then(({ data }) => {
        context.commit(SET_TEMPLATES, data.data);
        resolve(data);
      });
    });
  },
  [SAVE_TEMPLATE](context, payload) {
    return new Promise(resolve => {
      ApiService.post("/templates", payload).then(({ data }) => {
        context.commit(SET_TEMPLATE, data.data);
        resolve(data);
      });
    });
  },
  [SHOW_TEMPLATE](context, payload) {
    return new Promise(resolve => {
      ApiService.get("/templates", payload).then(({ data }) => {
        context.commit(SET_TEMPLATE, data.data);
        resolve(data);
      });
    });
  },
  [RESET_TEMPLATE](context) {
    context.commit(PURGE_CURRENT_TEMPLATE);
  },
  [DELETE_TEMPLATE](context, payload) {
    return new Promise(resolve => {
      ApiService.delete("/templates", payload).then(({ data }) => {
        context.commit(PURGE_TEMPLATE, payload);
        resolve(data);
      });
    });
  },
  [CREATE_TEMPLATE_FIELD](context, payload) {
    return new Promise(resolve => {
      ApiService.post("/template-fields", payload).then(({ data }) => {
        context.commit(SET_TEMPLATE_FIELD, data.data);
        resolve(data);
      });
    });
  },
  [DELETE_TEMPLATE_FIELD](context, payload) {
    return new Promise(resolve => {
      ApiService.delete("/template-fields", payload).then(({ data }) => {
        context.commit(PURGE_TEMPLATE_FIELD, payload);
        resolve(data);
      });
    });
  },
  [UPDATE_TEMPLATE_FIELD](context, payload) {
    return new Promise(resolve => {
      ApiService.update("/template-fields", payload.uuid, payload).then(
        ({ data }) => {
          context.commit(REPLACE_TEMPLATE_FIELD, payload);
          resolve(data);
        }
      );
    });
  },
  [DELETE_TEMPLATE_WEBHOOK](context, payload) {
    const { webhookUuid, templateUuid } = payload;
    return new Promise(resolve => {
      ApiService.delete(
          "/templates/" + templateUuid + "/webhook-endpoints",
          webhookUuid)
      .then(({ data }) => {
        context.commit(PURGE_TEMPLATE_WEBHOOK, webhookUuid);
        resolve(data);
      });
    });
  },
  [CREATE_TEMPLATE_WEBHOOK](context, payload) {
    const { uuid, url } = payload;
    return new Promise((resolve, reject) => {
      ApiService.post(`/templates/${uuid}/webhook-endpoints`, { url })
        .then(({ data }) => {
          context.commit(SET_TEMPLATE_WEBHOOK, data.data);
          resolve(data);
        })
        .catch(err => reject(err));
    });
  }
};

const mutations = {
  [SET_TEMPLATE](state, template) {
    state.current_template = template;
  },
  [SET_TEMPLATES](state, templates) {
    state.templates = templates;
  },

  [PURGE_CURRENT_TEMPLATE](state) {
    state.current_template = {
      uuid: "",
      name: "",
      type: "",
      fields: []
    };
  },
  [PURGE_TEMPLATE](state, uuid) {
    state.templates = Vue.lodash.remove(state.templates, template => {
      return template.uuid != uuid;
    });
  },
  [SET_TEMPLATE_FIELD](state, field) {
    state.current_template.fields = [...state.current_template.fields, field];
  },
  [PURGE_TEMPLATE_FIELD](state, uuid) {
    state.current_template.fields = Vue.lodash.remove(
      state.current_template.fields,
      field => {
        return field.uuid != uuid;
      }
    );
  },
  [REPLACE_TEMPLATE_FIELD](state, field) {
    //state.current_template.fields = state.current_template.fields.map(item => item.uuid === field.uuid ? field : item);

    state.current_template.fields = Vue.lodash.map(
      state.current_template.fields,
      item => {
        return item.uuid === field.uuid ? field : item;
      }
    );
  },
  [PURGE_TEMPLATE_WEBHOOK](state, uuid) {
    state.current_template.webhooks = Vue.lodash.remove(
      state.current_template.webhooks,
      field => field.id !== uuid
    );
  },
  [SET_TEMPLATE_WEBHOOK](state, webhook) {
    state.current_template.webhooks = [
      ...state.current_template.webhooks,
      webhook
    ];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
