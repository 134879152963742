import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const UPDATE_PASSWORD = "updateUserPassword";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";

const state = {};

const getters = {};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    return new Promise(resolve => {
      ApiService.post("/profile/update/personal-info", payload).then(
        ({ data }) => {
          context.commit(SET_PERSONAL_INFO, data.data);
          resolve(data);
        }
      );
    });
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    //context.commit(SET_ACCOUNT_INFO, payload);

    return new Promise(resolve => {
      ApiService.post("/profile/update/account-info", payload).then(
        ({ data }) => {
          context.commit(SET_ACCOUNT_INFO, data.data);
          resolve(data);
        }
      );
    });
  },
  [UPDATE_PASSWORD](context, payload) {
    return new Promise(resolve => {
      ApiService.post("/profile/update/password", payload).then(({ data }) => {
        resolve(data);
      });
    });
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
