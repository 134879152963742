import ApiService from "@/core/services/api.service";

export const LIST_FILES = "getFiles";

// mutation types
export const SET_FILES = "setFiles";

const state = {
  files: [],
  meta: { current_page: 1, total: 0, per_page: 15 }
};

const getters = {
  files(state) {
    return state.files;
  },
  meta(state) {
    return state.meta;
  }
};

const actions = {
  [LIST_FILES](context, payload) {
    return new Promise(resolve => {
      let page = typeof payload === "undefined" ? 1 : payload.page;
      ApiService.get("/profile/files?page=" + page).then(({ data }) => {
        context.commit(SET_FILES, data);
        resolve(data);
      });
    });
  }
};

const mutations = {
  [SET_FILES](state, data) {
    state.files = data.data;
    state.meta = data.meta;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
