import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const SANCTUM_CSRF = "sanctumCsrf";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
//export const UPDATE_PASSWORD = "updateUser";
export const LOGIN_JWT = "loginJwt";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
//export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  current_user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.current_user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [SANCTUM_CSRF](context) {
    return new Promise(resolve => {
      ApiService.get("/sanctum/csrf-cookie")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ error }) => {
          if (error) {
            if (error.data) {
              context.commit(SET_ERROR, error.data.errors);
            } else if (error.message) {
              context.commit(SET_ERROR, { errors: [error.message] });
            }
          }
        });
    });
  },
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("/login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ error }) => {
          if (error) {
            if (error.data) {
              context.commit(SET_ERROR, error.data.errors);
            } else if (error.message) {
              context.commit(SET_ERROR, { errors: [error.message] });
            }
          }
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("/register", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ error }) => {
          if (error) {
            if (error.data) {
              context.commit(SET_ERROR, error.data.errors);
            } else if (error.message) {
              context.commit(SET_ERROR, { errors: [error.message] });
            }
          }
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return new Promise(resolve => {
        ApiService.get("/verify")
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(error => {
            context.commit(PURGE_AUTH);
            if (error) {
              if (error.data) {
                context.commit(SET_ERROR, error.data.errors);
              } else if (error.message) {
                context.commit(SET_ERROR, { errors: [error.message] });
              }
            }
          });
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [LOGIN_JWT](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("/login-jwt", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ error }) => {
          if (error) {
            if (error.data) {
              context.commit(SET_ERROR, error.data.errors);
            } else if (error.message) {
              context.commit(SET_ERROR, { errors: [error.message] });
            }
          }
        });
    });
  } /*,
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }*/
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user, token = null) {
    state.isAuthenticated = true;
    state.current_user = user;
    state.errors = {};

    //console.dir(state.current_user);
    //console.dir(token);

    if (token) {
      JwtService.saveToken(token); // changed as per api
    } else {
      JwtService.saveToken(user.token.access_token); // changed as per api
    }
  },
  /*[SET_PASSWORD](state, password) {
    state.current_user.password = password;
  },*/
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.current_user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
